export const enterpriseEditionUrl = 'https://xd-twin.io/';

export const contactUrl:{ [locale:string]:string } = {
  en: 'https://xd-twin.io/',
  de: 'https://xd-twin.io/',
};

export const pricingUrl = 'https://xd-twin.io/';

export const enterpriseDocsUrl:{ [key:string]:string } = {
  website: 'https://xd-twin.io/',
  boards: 'https://xd-twin.io/',
  tableHighlighting: 'https://xd-twin.io/',
  graphWidgets: 'https://xd-twin.io/',
};
